.scheduling-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: $white;
  font-size: 0.875rem;
  .custom-control {
    font-size: 0.75rem;
    .custom-control-label {
      &::before,
      &::after {
        top: 0;
      }
    }
  }
}

.toggle-calendar {
  position: absolute;
  top: -1rem;
  right: 1rem;
  z-index: 50;
  display: none;
  border-color: $gray-lighten-10;
  background-color: $gray-lighten-10;
  color: $white;
}
.scheduling-footer-content {
  box-shadow: 0px -4px 14px rgba(199, 203, 210, 0.4);
  background-color: $white;
  .scheduling-footer-calendar {
    padding-top: $gap-xs;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
  }
}
@include media-breakpoint-up(lg) {
  .preview-bottom-calendar {
    &.preview-container {
      padding-bottom: 16rem;
    }
  }
  .scheduling-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 53;
  }
  .scheduling-footer-content {
    position: fixed;
    bottom: 50px;
    left: 0;
    right: 0;
    z-index: 51;
  }
}

@include media-breakpoint-down(sm) {
  .scheduling-footer {
    height: auto;
    padding-top: $gap-xs;
    padding-bottom: $gap-xs;
  }
  .scheduling-footer-content {
    bottom: 100px;
    height: 213px;
    &.collapse {
      .scheduling-footer-calendar {
        height: 0;
        transition: height 0.3s ease-in-out;
      }
    }
  }
  .toggle-calendar {
    display: block;
    .icon {
      display: block;
      transition: all 0.2s ease-in-out;
    }
    &.active-collapse {
      .icon {
        transform: rotate(-180deg);
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
