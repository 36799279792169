.white-panel {
  border-radius: 8px;
  background-color: $white;
  color: $gray-base;
}

.cloud-panel {
  border-radius: 8px;
  background-color: $gray-lighten-60;
}

.meta-panel {
  padding: 0.75rem 1rem;
  border-radius: 8px;
  background: $blue-lighten-50;
;
}
.meta-panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $blue-lighten-30;
  &.border-none {
    padding-bottom: 0;
    border-bottom: none;
  }
  .title {
    color: $gray-darken-20;
    font-family: $font-family-semibold;
    font-size: 13px;
    line-height: 16px;
  }
}

@include media-breakpoint-up(md) {
  .preview-panel {
    margin-top: 2rem;
    padding: 2rem;
  }
}
@include media-breakpoint-down(sm) {
  .preview-panel {
    padding: 1rem;
  }
}