.preview-header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  box-shadow: $elevation-header;
  background-color: $white;
  .title {
    flex-grow: 1;
    color: $gray-darken-40;
    font-family: $font-family-medium;
    font-weight: $font-family-weight;
    font-size: 1.25rem;
    text-align: center;
  }
  .back {
    position: absolute;
    top: 50%;
    left: $gap-xxs;
    width: 35px;
    height: 35px;
    margin-top: -17px;
  }
}

@include media-breakpoint-up(lg) {
  .preview-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1051;
    .simulate & {
      top: $simulate-header-height;
    }
  }
  .preview-header-position {
    .preview-header {
      .simulate & {
        top: 78px;
      }
    }
  }
}
