.app-table-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  .additional-actions {
    margin-left: auto;
  }
  .bulk-actions {
      display: flex;
      align-items: center;
  }
  .bulk-label {
    color: $gray-lighten-30;
    font-family: $font-family-semibold;
    font-size: 11px;
    line-height: 11px;
  }
  .btn-bulk {
    font-size: 1.438rem;
  }
}
