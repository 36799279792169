.pill-tabs {
  .nav {
    display: inline-flex;
    margin: 0;
    padding: 0;
    border-radius: 30px;
    background-color: $gray-lighten-60;
    list-style: none;
  }
  .nav-item {
    .nav-link {
      display: inline-block;
      padding: $gap-xxs $gap-xs;
      border-radius: 30px;
      color: $gray-base;
      font-size: 0.75rem;
      line-height: 1;
      letter-spacing: 1px;
      text-transform: uppercase;
      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: 0px 2px 5px rgba(181, 200, 239, 0.6);
        background-color: $white;
        color: $gray-lighten-10;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
      }
    }
    &.active {
      .nav-link {
        box-shadow: 0px 2px 5px rgba(181, 200, 239, 0.6);
        background-color: $blue-lighten-30;
        color: $white;
        &:hover {
          background-color: $blue-lighten-30;
          color: $white;
          cursor: default;
        }
      }
    }
  }
}

.filter-tabs {
  .nav {
    display: flex;
    margin: 0;
    padding: 0 1rem;
    list-style: none;
  }
  .nav-item {
    .nav-link {
      position: relative;
      display: inline-block;
      padding: 1rem 1rem 0.625rem;
      color: $gray-base;
      font-family: $font-family-medium;
      font-size: 0.875rem;
      transition: all 0.2s ease-in-out;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: transparent;
      }
      &:hover {
        color: $gray-darken-50;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
      }
    }
    &.active {
      .nav-link {
        color: $gray-darken-50;
        &::after {
          background: $blue-base;
        }
      }
    }
  }
}

.filter-tabs-columns {
  display: flex;
  .nav-tabs {
    flex: 0 0 30%;
    max-width: 30%;
    padding: 0 2rem 0 0;
    border-right: 1px solid $gray-lighten-40;
    .nav-item {
      .nav-link {
        display: block;
        padding: 0.5rem 1rem;
        border-left: 2px solid transparent;
        &::after {
          display: none;
        }
      }
      &.active .nav-link,
      &.active:hover .nav-link {
        color: $blue-darken-10;
        background-color: $blue-lighten-50;
        border-left: 2px solid $blue-base;
      }
      &:hover .nav-link {
        background-color: $gray-lighten-60;
        border-left: 2px solid $blue-base;
      }
    }
  }
  .tab-content {
    flex-grow: 1;
  }
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    flex-direction: column;
    .nav-tabs {
      flex: 0 0 auto;
      max-width: 100%;
      margin-bottom: 2rem;
      padding: 0;
      border-right: none;
    }
  }
}
