.chart-container {
  width: 100px;
  height: 100px;
}
.legend-total {
  color: $gray-darken-20;
  font-size: 1.5rem;
  line-height: 1;
}

.chart-legend {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    display: flex;
    margin-bottom: 0.25rem;
    color: $gray-lighten-10;
    font-size: 0.75rem;
    line-height: 0.875rem;
    &::before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 5px;
      margin-top: 0.375rem;
      margin-right: $gap-xxs;
      border-radius: 2px;
      background: gray;
    }
    &.typeNormal {
      &::before {
        background-color: #92a7ba;
      }
    }
    &.typeAdvertise {
      &::before {
        background-color: #379b7e;
      }
    }
    &.typeOptionalRoster {
      &::before {
        background-color: #d96d04;
      }
    }
    &.typeRegularRoster {
      &::before {
        background-color: #d4d0fa;
      }
    }
    &.typePriorityRoster {
      &::before {
        background-color: #847cf3;
      }
    }
    &.typeAMA {
      &::before {
        background-color: #dcba07;
      }
    }
    &.typeWithoutActivities {
      &::before {
        background-color: #f24c42;
      }
    }
    &.typeWithActivities {
      &::before {
        background-color: #baca13;
      }
    }
    &.typeExcluded {
      &::before {
        background-color: #ffcb8d;
      }
    }
    &.typeNotRegistered {
      &::before {
        background-color: #fbaf97;
      }
    }
    &.typeRegistered {
      &::before {
        background-color: #baca13;
      }
    }
    &.typeAttendancePending {
      &::before {
        background-color: #89cef6;
      }
    }
    &.typeAttendanceTaken {
      &::before {
        background-color: #00a2ff;
      }
    }
    &.typeStudentPresent {
      &::before {
        background-color: #baca13;
      }
    }
    &.typeStudentLateExcused {
      &::before {
        background-color: #ffcb8d;
      }
    }
    &.typeStudentLateUnexcused {
      &::before {
        background-color: #fbaf97;
      }
    }
  }
}
