.notifications-broadcast {
  position: fixed;
  padding: $gap-xxs;
  z-index: 1052;
}
.notification-card {
  display: flex;
  width: 100%;
  max-width: 390px;
  padding: $gap-xs;
  border: 1px solid $primary;
  box-shadow: 0px 1px 9px rgba(181, 200, 239, 0.6);
  background: $white;
  margin-bottom: 20px;
}
.notification-group {
  display: flex;
  padding: 0 $gap-xxs;
  margin-top: $gap-xxs;
  .date {
    display: inline-block;
    padding: 0 $gap-xxs;
    border-radius: 4px 4px 0 0;
    background-color: $blue-lighten-30;
    color: $gray-lighten-70;
    font-size: 0.75rem;
    font-weight: 600;
  }
}
.notification-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.notification-list-item {
  display: flex;
  width: 100%;
  padding: 16px 16px 16px 18px;
  border-bottom: 1px solid $border;
  background: $white;
  &.unread {
    border-color: $blue-lighten-20;
  }
  &:first-of-type {
    border-radius: 1rem 1rem 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 1rem 1rem;
    border-color: transparent;
  }
}

.notification-icon {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  margin-right: $gap-xs;
  border-radius: 30px;
  background: rgba(137, 206, 246, 0.3);
  color: $primary;
  font-size: 1.75rem;
  line-height: 3rem;
  text-align: center;
}

.notification-body {
  flex-grow: 1;
}
.notification-title {
  color: $primary;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
}

.notification-time {
  color: $blue-lighten-30;
  font-size: 0.75rem;
  line-height: 1;
}

.notification-link {
  color: var(--ftm-primary, #0073E6);
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px; /* 100% */
  text-transform: uppercase;
}

.notifications-position {
  bottom: 50px;
  left: $side-menu-width;
  .notification-horizontal & {
    top: 50px;
    bottom: auto;
    right: 0;
    left: auto;
  }
}

.notification-radius {
  border-radius: 1rem 1rem 1rem 0;
  .notification-horizontal & {
    border-radius: 1rem 0 1rem 1rem;
  }
}

@include media-breakpoint-down(lg) {
  .notifications-position {
    top: $page-header-height;
    left: $gap-xxs;
    .notification-horizontal & {
    }
  }
  .notification-radius {
    border-radius: 0 1rem 1rem 1rem;
  }
}

@include media-breakpoint-down(sm) {
  .notfification-card {
    padding: $gap-xs $gap-xxs $gap-xs $gap-xs;
  }
}
