.alert {
  display: flex;
  padding: $gap-xs;
  border-radius: 4px;
  background-color: $blue-lighten-50;
  color: $gray-lighten-10;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  .icon {
    color: $gray-base;
    font-size: 1.25rem;
  }
  .server-alert & {
    max-width: 100%;
    padding: 0.75rem 1rem;
    box-shadow: 0px 4px 8px rgba(138, 151, 158, 0.5);
    text-align: left;
  }
  &.alert-dismissible {
    .close {
      float: right;
      padding: 0.125rem 0.5rem;
      border: none;
      border-radius: 8px;
      background-color: transparent;
      color: $white;
      font-size: 0.75rem;
      font-weight: 400;
      text-transform: uppercase;
    }
    .alert-body {
      float: left;
      margin-right: 2rem;
      font-family: $font-family-semibold;
    }
    &::after {
      content: '';
      clear: both;
      display: table;
    }
  }
  &.alert-success {
    background-color: $green-lighten-20;
    color: $gray-darken-20;
    .close {
      color: $blue-darken-10;
    }
  }
  &.alert-danger {
    background-color: $red-lighten-10;
    color: $white;
  }
  &.alert-warning {
    background-color: $orange-lighten-20;
    color: $gray-darken-20;
    .close {
      color: $blue-darken-10;
    }
  }
  &.alert-info {
    background-color: $gray-darken-20;
    color: $white;
    .close {
      color: $blue-base;
    }
  }
  &-primary-outline {
    border: 1px solid $blue-base;
    background: $white;
    color: $gray-darken-50;
  }
  &.alert-canceled {
    background-color: #fddfd6;
    color: $red-lighten-10;
  }
  &-na {
    background-color: $white;
    color: $alert-na;
  }
  &-primary {
    background-color: $blue-base;
    color: $white;
  }
  &-primary-light {
    background-color: $blue-lighten-30;
    color: $blue-darken-10;
  }
  &-square {
    border-radius: 0;
  }
  &-full-h {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.alert-fixed-width {
  max-width: 436px;
  margin-top: 25px;
  margin-bottom: 15px;
  .content {
    font-family: $font-family-semibold;
    font-size: 15px;
    line-height: 26px;
  }
  &.success {
    .alert-success {
      background-color: $green-lighten-40;
      .alert-content {
        color: $green-base;
      }
    }
  }
  &.danger {
    .alert-danger {
      background-color: $red-lighten-30;
      .alert-content {
        color: $red-darken-10;
      }
    }
  }
}
