.bs-datepicker {
  border-radius: $border-radius;
  box-shadow: 0px 4px 11px rgba(181, 200, 239, 0.6);
  .bs-datepicker-container {
    padding: 0;
  }

  .bs-datepicker-head {
    height: 66px;
    padding: 16px;
    border-radius: $border-radius $border-radius 0 0;
    background-color: $blue-base;
    button {
      text-transform: capitalize;
    }
  }
  .bs-datepicker-body {
    padding: 16px;
    border-radius: 0 0 $border-radius $border-radius;
    table {
      th {
        padding: 0.25rem 0 0.75rem 0;
        color: $gray-darken-50;
        font-size: 12px;
        font-family: $font-family-semibold;
        text-transform: uppercase;
      }
      td {
        height: auto;
        color: $gray-darken-50;
        span {
          font-size: 12px;
        }
        &.week span {
          color: $blue-base;
        }
        span.selected,
        &.selected span,
        span[class*='select-']:after,
        &[class*='select-'] span:after {
          background-color: $blue-base;
        }
        span.is-other-month,
        &.is-other-month span {
          color: $gray-lighten-10;
        }
      }
      &.days span {
        width: 35px;
        height: 35px;
        line-height: 35px;
      }
    }
  }
  .btn-clear-wrapper {
    .btn-success {
      margin-bottom: 0.5rem;
      padding: 0.125rem 2rem;
      border: 1px solid $border-color;
      background-color: transparent;
      color: $gray-lighten-30;
      font-size: 0.75rem;
      line-height: 1.125rem;
      &:hover {
        border-color: $blue-base;
        background-color: $blue-base;
        color: $white;
      }
    }
  }
}

.priority-wrapper .round-btn.date-btn,
.date-btn {
  font-size: 0.75rem;
  &:hover {
    border-color: transparent;
    background-color: inherit;
    color: inherit;
    cursor: pointer;
  }
  /* future date */
  &.available {
    background-color: $white;
    color: $blue-base;
    border: 1px dashed $blue-base;
    &:hover {
      box-shadow: $focus;
      cursor: pointer;
    }
  }
  &.disabled {
    background-color: $blue-lighten-40;
    color: $white;
  }
  &.selected {
    background-color: $blue-lighten-20;
    color: $blue-darken-30;
    border: 1px dashed $blue-darken-30;
    &:hover {
      cursor: pointer;
    }
  }
  /* current date */
  &-current {
    font-size: 0.75rem;
    &.available {
      background-color: $white;
      color: $blue-base;
      border: 1px solid $blue-base;
      &:hover {
        cursor: pointer;
      }
    }
    &.disabled {
      background-color: $blue-lighten-40;
      color: $white;
    }
    &.selected {
      background-color: $blue-lighten-20;
      color: $blue-darken-30;
      border: 1px solid $blue-lighten-20;
      &:hover {
        cursor: pointer;
      }
    }
  }
  /* past date */
  &-past {
    font-size: 0.75rem;
    &.available {
      background-color: $blue-lighten-50;
      color: $blue-lighten-30;
      border: 1px solid $blue-lighten-30;
      &:hover {
        cursor: pointer;
      }
    }
    &.disabled {
      background-color: $blue-lighten-50;
      color: $blue-lighten-30;
    }
    &.selected {
      background-color: $blue-lighten-30;
      color: $white;
    }
  }
}