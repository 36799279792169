h1,
h2,
h3,
h4,
h5,
h6 {
  color: $headings-color;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
}

h3 {
  font-size: 21px;
}

b, strong {
  font-family: $font-family-bold;
  font-weight: $font-family-weight;
}

.body-medium {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.preview-title {
  font-family: $font-family-semibold;
  font-size: 20px;
  line-height: 22px;
}

.meta-label {
  margin: 0;
  font-family: $font-family-semibold;
  font-size: 12px;
  line-height: 1.3;
}
.meta-value {
  font-size: 14px;
  line-height: 1.2;
}
.meta-icon {
  font-size: 1rem;
  line-height: 1;
}
.footer-meta {
  font-size: 14px;
  line-height: 1.2;
  b {
    color: $blue-base;
  }
}

.heading-count {
  color: $blue-base;
  font-size: 16px;
  .total {
    color: $gray-lighten-30;
    font-family: $font-family-regular;
  }
}

.days-range {
  font-size: 0.75rem;
}
.days-count {
  font-size: 0.8125rem;
  font-family: $font-family-semibold;
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.caption {
  color: $gray-darken-20;
  font-family: $font-family-semibold;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
}
