.app-filter-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 55px;
  padding: $gap-xxs $gap-xs;
  .filter-embed & {
    border-radius: $border-radius;
    &.filter-collapsed {
      border-radius: $border-radius $border-radius 0 0;
    }
  }
}

.app-filter-collapse {
  position: relative;
  padding-top: 1rem;
  border-top: 1px solid $border-light;
  background-color: $white;
  .clear {
    color: $gray-darken-20;
    font-size: 0.75rem;
  }
  .filter-embed & {
    border-radius: 0 0 $border-radius $border-radius;
  }
}

.toggle-filter {
  &.filter-on {
    background-color: $gray-lighten-60;
    border-color: $gray-lighten-60;
  }
}

@include media-breakpoint-up(lg) {
  .search-resp {
    min-width: 360px;
  }
}

@include media-breakpoint-down(sm) {
  .search-resp {
    width: 100%;
  }
}
