html,
body,
.app-wrapper {
  height: 100%;
}

.dashboard-bar {
  border-color: $white;
  box-shadow: $elevation-header;
}
.simulate {
  padding-top: $simulate-header-height;
}
.simulate-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: $simulate-header-height;
  background: $blue-lighten-50;
}

.preview-container-upper {
  padding-top: $page-header-height;
}
.preview-container {
  padding-top: $gap-xs;
  padding-bottom: $gap-md;
  @include media-breakpoint-up(lg) {
    padding-top: $page-header-height;
    padding-bottom: 10rem;
  }
  .simulate & {
    padding-top: $simulate-header-height + $page-header-height;
  }
  &.priority-wrapper {
    padding-top: $page-header-height + 32px;
    padding-bottom: 5rem;
    .simulate & {
      padding-top: $simulate-header-height + $page-header-height + 32px;
    }
  }
}

.preview-summary {
  margin-bottom: $gap-xs;
  padding-top: 1.5rem;
  padding-bottom: $gap-xs;
}

.main-content-wrapper {
  padding-top: $page-header-height;
  padding-left: $side-menu-width;
  &.main-content-wrapper-horizontal {
    padding-left: 0;
  }
}

.prio-legend-bar {
  .main-content-wrapper-horizontal & {
    left: 0;
  }
}

.activity-type-header {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 50;
  padding: 0.5rem;
  background: $gray-lighten-10;
  color: $white;
  font-family: $font-family-semibold;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
}

.border-bottom {
  border-bottom: 1px solid $border-light;
}
.border-top {
  border-top: 1px solid $border-light;
}
.overflow-auto {
  overflow: auto;
}

.datepicker-transform {
  .datepicker-mask {
    .icon {
      display: none;
    }
    .date-picker {
      max-width: 85px;
    }
    label {
      margin: 0;
      font-size: 0.875rem;
      line-height: 2.2;
      text-transform: uppercase;
    }
  }
  .btn-pill {
    display: none;
  }
}
// Click area for prev/next datepicker buttons
.daypicker-nav {
  z-index: 2;
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.blank-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: $gap-xs;
  border-bottom: 1px solid $border;
  text-align: center;
}
.blank-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.cs-wrapper {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cs-block {
  max-width: 320px;
  text-align: center;
}
.coming-soon-text {
  margin-bottom: 1.5rem;
  color: $gray-lighten-30;
  font-size: 1.75rem;
  font-family: $font-family-regular;
  line-height: 1.3;
}
.coming-soon-loader {
  border: 4px solid $gray-lighten-30;
  border-top: 4px solid $blue-base;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin: auto;
  animation: spin 1s linear infinite;
}

.dashboard-table {
  position: relative;
  .additional-actions {
    position: absolute;
    top: 0;
    right: 0;
  }
  .app-table-actions {
    height: 0;
  }
}

.header-calendar {
  box-shadow: $elevation-header;
  background-color: $white;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include media-breakpoint-down(lg) {
  .main-content-wrapper {
    padding-left: 0;
  }
}

@include media-breakpoint-up(md) {
  .mh-sm {
    min-height: 200px;
  }
}

@include media-breakpoint-down(sm) {
  .datepicker-mask {
    max-width: 90px;
    .icon {
      display: none;
    }
    label {
      flex-direction: column;
      b {
        line-height: 1;
      }
    }
  }
}
