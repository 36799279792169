@import 'theme/app';

 
.tooltip-example {
  text-align: center;
  padding: 0 50px;
}
.tooltip-example [appTooltip] {
  display: inline-block;
  margin: 50px 20px;
  width: 180px;
  height: 50px;
  border: 1px solid $gray-darken-30;
  border-radius: 5px;
  line-height: 50px;
  text-align: center;
}
.ng-tooltip {
  position: absolute;
  font-size: 12px;
  text-align: center;
  color: $gray-lighten-60;
  padding: 3px 8px;
  background: $gray-darken-30;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 1s;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: $gray-darken-30 transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent $gray-darken-30 transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent $gray-darken-30;
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent $gray-darken-30 transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
  z-index: 11111;
}

.pointer-cursor{
  cursor: pointer;
}

.default-activity-modal-max-height .modal-body{
  max-height: 70vh;
  overflow-y: scroll;
}

.custom-modal.modal-dialog.prompt-modal {
  top: calc(50% - 182px);
  box-shadow: $elevation-3;
}

.alert.alert-success{
  color: $white
}

.round-btn:disabled{
  cursor: default;
}

.custom-control-input:disabled ~ .custom-control-label {
  opacity: 0.5;
  cursor: not-allowed;
}

.custom-control-input:disabled {
  cursor: not-allowed;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: $gray-lighten-50;
  border-color: $gray-lighten-30;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: $gray-lighten-20;
}

ng-select {
  padding: 0 !important;
}

ng-select.ng-select-disabled .ng-select-container {
  background-color: $gray-lighten-50 !important;
  cursor: not-allowed;
}

ng-select .ng-select-container {
  border-radius: 3px;
}
.ftmi-default-activity.icon {
  background-image: url('./assets/images/default-activity.svg');
}

.preview-header{
  position: fixed;
  z-index: 1;
}