.priority-wrapper {
  color: $gray-darken-50;
  .priority-color {
    color: $blue-base;
  }
  .priority-border {
    border-color: $blue-base;
  }
  .priority-background {
    background: $blue-lighten-30;
    .icon {
      color: $gray-darken-50;
    }
    &.btn-text-icon {
      color: $gray-darken-50;
    }
    &.round-btn {
      &:hover {
        color: $blue-base;
      }
    }
  }
  &.priority-ama {
    .priority-color {
      color: $priority-admin-dark;
    }
    .priority-border {
      border-color: $priority-admin-dark;
    }
    .priority-background {
      color: $gray-darken-40;
      background: $priority-admin;
    }
    .round-btn {
      &:hover {
        border-color: $priority-admin-dark;
        background-color: $priority-admin-dark;
      }
    }
  }
  &.priority-pr {
    .priority-color {
      color: $priority-priority-roster-dark;
    }
    .priority-border {
      border-color: $priority-priority-roster-dark;
    }
    .priority-background {
      color: $gray-darken-40;
      background: $priority-priority-roster;
    }
    .round-btn {
      &:hover {
        border-color: $priority-priority-roster-dark;
        background-color: $priority-priority-roster-dark;
      }
    }
  }
  &.priority-tr {
    .priority-color {
      color: $priority-teacher-request-dark;
    }
    .priority-border {
      border-color: $priority-teacher-request-dark;
    }
    .priority-background {
      color: $gray-darken-40;
      background: $priority-teacher-request;
    }
    .round-btn {
      &:hover {
        border-color: $priority-teacher-request-dark;
        background-color: $priority-teacher-request-dark;
      }
    }
  }
  &.priority-rr {
    .priority-color {
      color: $priority-regular-roster-dark;
    }
    .priority-border {
      border-color: $priority-regular-roster-dark;
    }
    .priority-background {
      color: $gray-darken-40;
      background: $priority-regular-roster;
    }
    .round-btn {
      &:hover {
        border-color: $priority-regular-roster-dark;
        background-color: $priority-regular-roster-dark;
      }
    } 
  }
  &.priority-or {
    .priority-color {
      color: $priority-optional-roster-dark;
    }
    .priority-border {
      border-color: $priority-optional-roster-dark;
    }
    .priority-background {
      color: $gray-darken-40;
      background: $priority-optional-roster;
    }
    .round-btn {
      &:hover {
        border-color: $priority-optional-roster-dark;
        background-color: $priority-optional-roster-dark;
      }
    }
  }
  &.priority-aa {
    .priority-color {
      color: $priority-advertise-dark;
    }
    .priority-border {
      border-color: $priority-advertise-dark;
    }
    .priority-background {
      color: $gray-darken-40;
      background: $priority-advertise;
    }
    .round-btn {
      &:hover {
        border-color: $priority-advertise-dark;
        background-color: $priority-advertise-dark;
      }
    }
  }
  &.priority-na {
    .priority-color {
      color: $priority-normal-dark;
    }
    .priority-border {
      border-color: $priority-normal-dark;
    }
    .priority-background {
      color: $gray-darken-30;
      background: $priority-normal;
    }
    .round-btn {
      &:hover {
        border-color: $priority-normal-dark;
        background-color: $priority-normal-dark;
      }
    }
  }
  &.priority-catchall {
    .priority-color {
      color: $priority-catchall-dark;
    }
    .priority-border {
      border-color: $priority-catchall-dark;
    }
    .priority-background {
      color: $gray-darken-40;
      background: $priority-catchall;
    }
    .round-btn {
      &:hover {
        border-color: $priority-catchall-dark;
        background-color: $priority-catchall-dark;
      }
    }
  }
  &.priority-lunch {
    .priority-color {
      color: $priority-lunch-dark;
    }
    .priority-border {
      border-color: $priority-lunch-dark;
    }
    .priority-background {
      color: $gray-darken-40;
      background: $priority-lunch;
    }
    .round-btn {
      &:hover {
        border-color: $priority-lunch-dark;
        background-color: $priority-lunch-dark;
      }
    }
  }
}
