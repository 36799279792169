.btn {
  &-calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      margin-right: 0.5rem;
      font-size: 1.5rem;
    }
  }
  &-pill {
    display: inline-block;
    padding: $gap-xxs $gap-xs;
    border-radius: 30px;
    border: none;
    background-color: $blue-lighten-30;
    color: $gray-base;
    font-size: 0.813rem;
    line-height: 1;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    .segment-group & {
      background-color: $white;
    }
    &:hover {
      box-shadow: 0px 2px 5px rgba(181, 200, 239, 0.6);
      background-color: $white;
      color: $gray-lighten-10;
      transition: all 0.2s ease-in-out;
    }
    &:focus {
      box-shadow: $focus;
      transition: all 0.2s ease-in-out;
    }
    &:disabled {
      background-color: $blue-lighten-30;
      color: $white;
    }
    &.btn-today {
      background-color: $blue-base;
      color: $white;
      &:disabled {
        background-color: $gray-lighten-50;
        color: $gray-base;
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
  &-meta {
    display: flex;
    padding: 0.25rem 0.5rem;
    color: $primary;
    font-family: $font-family-semibold;
    font-size: 0.75rem;
    &:hover {
      color: $primary;
      background-color: $blue-lighten-60;
    }
    &:focus {
      box-shadow: $focus;
    }
    &:active, &.active {
      color: $primary;
      background-color: $blue-lighten-50;
    }
    .icon {
      margin-right: 0.5rem;
      font-size: 1.125rem;
    }
  }
  &-text {
    color: $primary;
    font-family: $font-family-semibold;
    font-size: 0.8125rem;
    line-height: 1;
  }
  &-text-icon {
    display: flex;
    align-items: center;
    padding: 0.125rem 1rem 0.125rem 1rem;
    border-radius: 30px;
    background-color: $blue-lighten-50;
    color: $gray-lighten-30;
    font-family: $font-family-regular;
    letter-spacing: 1px;
    font-size: 0.625rem;
    line-height: 1.125rem;
    .icon {
      color: $primary;
      font-size: 1.125rem;
    }
    .icon-l {
      font-size: 1.25rem;
    }
    &.bordered {
      border: 1px solid $border-color;
      background-color: transparent;
      &:hover,
      &:focus {
        background-color: $blue-base;
        color: $white !important;
      }
    }
    &.bordered-primary {
      color: $primary;
    }
  }
  &-padded {
    padding-left: $gap-sm;
    padding-right: $gap-sm;
  }
  &-padded-xs-gap {
    padding-left: $gap-xs;
    padding-right: $gap-xs;
  }
  &-radius {
    border-radius: 35px;
  }
  &-plus-add {
    padding: 2px 6px;
    font-size: 13px;
    line-height: 1;
  }
  &-outline-primary {
    background-color: $white;
  }
  &-sm {
    letter-spacing: 0.5px;
    .icon {
      font-size: 1rem;
      &.icon-left {
        margin-right: 0.5rem;
      }
      &.icon-right {
        margin-left: 0.5rem;
      }
    }
  }
  &-lock-toggle {
    padding: 0.125rem;
    border-radius: 0.25rem;
    background-color: $white;
    color: $gray-base;
    font-size: 1rem;
    line-height: 1;
    &.locked {
      background-color: transparent;
      color: $red-base;
    }
  }
}

.round-btn {
  display: inline-block;
  height: 35px;
  width: 35px;
  padding: 0;
  border: 2px solid transparent;
  border-radius: 100%;
  background-color: transparent;
  color: inherit;
  font-size: 21px;
  line-height: 35px;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: $blue-lighten-40;
    background-color: $blue-lighten-40;
    transition: all 0.2s ease-in-out;
    color: inherit;
  }
  &:focus {
    box-shadow: $focus;
    transition: all 0.2s ease-in-out;
  }
  &.active {
    border-color: $primary;
    color: $primary;
  }
  &.disabled {
    &:hover,
    &:focus {
      border-color: transparent;
      background-color: $blue-lighten-30;
    }
    cursor: default;
  }
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }
  &-xs {
    height: 26px;
    width: 26px;
    font-size: 14px;
    line-height: 26px;
  }
  &-primary {
    border-color: $blue-base;
    background-color: $blue-base;
    color: $white;
  }
  &-dark {
    border-color: $gray-darken-20;
    background-color: $gray-darken-20;
    color: $white;
  }
  &-plain {
    color: $gray-darken-20;
  }
  &.status-active {
    color: $green-base;
  }
  &.btn-back {
    &:hover,
    &:focus {
      box-shadow: none;
      border-color: transparent;
      background-color: transparent;
      color: $blue-base;
    }
  }
  &.btn-expand-toggle {
    &:hover,
    &:focus {
      box-shadow: none;
      border-color: transparent;
      background-color: transparent;
      color: $gray-darken-20;
    }
    &.collapsed {
      transform: rotate(-90deg);
    }
  }
  &-cta {
    display: block;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 35px;
  }
  &.btn-more-xs {
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.group-header-btn {
  background-color: transparent;
  color: $gray-darken-50;
  .icon {
    color: $gray-darken-50;
  }
  &:hover {
    border-color: $gray-lighten-40;
    background-color: $gray-lighten-40;
  }
}

.submit-attendance {
  &:disabled {
    border-color: transparent;
    background-color: transparent;
    color: $blue-base;
    cursor: default;
    &:hover {
      border-color: transparent;
      background-color: transparent;
      color: $blue-base;
    }
  }
  &:hover {
    border-color: $gray-lighten-40;
    background-color: $gray-lighten-40;
    background-color: $gray-lighten-40;
  }
}

.counter-btn {
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font-size: 0.813rem;
  font-family: $font-family-bold;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  .icon {
    font-size: 21px;
  }
  &:hover {
    color: $blue-base;
  }
}

@include media-breakpoint-up(sm) {
  .btn-md-padded {
    padding-left: $gap-sm;
    padding-right: $gap-sm;
  }
}
@include media-breakpoint-up(lg) {
  .btn-week-day {
    width: 100%;
  }
  .btn-week-day-half {
    float: left;
  }
}
