.dropdown-item {
  position: relative;
  font-family: $font-family-regular;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 3px;
    height: 100%;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
  }
  &:hover,
  &:active,
  &.active {
    &::before {
      background-color: $blue-base;
      transition: all 0.2s ease-in-out;
    }
  }
}
