// Fonts and iconfonts
@import 'fonts';
@import 'iconfont';

// Custom variables
@import 'custom-variables';

/* angular-cli file: src/styles.css */
@import '../node_modules/angular-calendar/css/angular-calendar.css';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

// @import "~ngx-bootstrap/datepicker/bs-datepicker.scss";
@import '@ng-select/ng-select/themes/default.theme.css';

// Bootstrap partials
@import 'bootstrap-imports';

// Custom styles
@import 'app-alert';
@import 'app-announcement';
@import 'app-badges';
@import 'app-calendar-nav';
@import 'app-charts';
@import 'app-filter-bar';
@import 'app-flex-period-header';
@import 'app-general-list';
@import 'app-layout';
@import 'app-list-group';
@import 'app-menu';
@import 'app-meta-bar';
@import 'app-notifications';
@import 'app-notes-list';
@import 'app-page-header';
@import 'app-panels';
@import 'app-preview-header';
@import 'app-priority-coloration';
@import 'app-priority-legend';
@import 'app-prompt';
@import 'app-qr-code';
@import 'app-scheduling-footer';
@import 'app-table-actions';
@import 'app-input';

@import 'buttons';
@import 'datepicker';
@import 'dropdowns';
@import 'forms';

@import 'modals';
@import 'scrollbar';
@import 'switcher';
@import 'tabs';
@import 'type';
@import 'utilities';

@import 'angular-calendar';
@import 'ngx-datatable';
@import 'app-notifications';
