.notes-list {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 250px;
  overflow-y: auto;
}

.notes-list-item {
  margin-bottom: $gap-xxs;
  padding: $gap-xs;
  border-radius: $border-radius;
  background-color: $gray-lighten-60;
  .author {
    color: $gray-darken-20;
    font-size: 0.875rem;
    font-family: $font-family-semibold;
  }
  .date {
    font-size: 0.625rem;
  }
  .description {
    color: $gray-lighten-30;
    font-size: 0.75rem;
  }
}
