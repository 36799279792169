html {
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

// Vendor
@import "../../node_modules/bootstrap/scss/vendor/rfs";

// Mixins

@import "bootstrap/scss/mixins/border-radius";
@import "bootstrap/scss/mixins/box-shadow";
@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/mixins/buttons";
@import "bootstrap/scss/mixins/caret";
@import "bootstrap/scss/mixins/deprecate";
@import "bootstrap/scss/mixins/forms";
@import "bootstrap/scss/mixins/gradients";
@import "bootstrap/scss/mixins/grid-framework";
@import "bootstrap/scss/mixins/grid";
@import "bootstrap/scss/mixins/hover";
@import "bootstrap/scss/mixins/nav-divider";
@import "bootstrap/scss/mixins/transition";
@import "bootstrap/scss/mixins/text-emphasis";
@import "bootstrap/scss/mixins/text-hide";
@import "bootstrap/scss/mixins/text-truncate";

// General 

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/reboot";

// Grid & utilities

@import "bootstrap/scss/grid";
@import "bootstrap/scss/utilities/display";
@import "bootstrap/scss/utilities/flex";
@import "bootstrap/scss/utilities/spacing";
@import "bootstrap/scss/utilities/text";

// Buttons

@import "bootstrap/scss/buttons";


// Forms

@import "bootstrap/scss/forms";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/custom-forms";

// Modal

@import "bootstrap/scss/modal";

// Utilities

@import "bootstrap/scss/utilities/spacing";

// Dropdown

@import "bootstrap/scss/dropdown";