.app-monthly-calendar {
  .cal-month-view {
    padding: 0.5rem;
    margin-bottom: 5rem;
  }
  .cal-month-view .cal-header .cal-cell {
    font-family: $font-family-regular;
    font-weight: $font-family-weight;
    font-size: 1rem;
    text-transform: uppercase;
  }
  .cal-month-view .cal-day-number {
    opacity: 1;
  }
  .cal-day-cell.cal-out-month .cal-day-number {
    opacity: 1;
  }
  .cal-cell.cal-day-cell.cal-weekend {
    background-color: $gray-lighten-60;
    .cal-day-number {
      color: $gray-lighten-10;
    }
  }
  .cal-cell.cal-day-cell.cal-past {
    .cal-day-number {
      color: $gray-lighten-10;
    }
  }

  .cal-month-view .cal-day-number {
    color: $gray-lighten-30;
    font-family: $font-family-semibold;
  }
  .cal-day-cell.cal-today {
    background-color: $white;
    .cal-day-number {
      color: $blue-base;
      font-size: 1.2em;
    }
  }
  .cal-cell-row .cal-cell {
    transition: all 0.2s ease-in-out;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: $gray-lighten-60;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
  }

  .cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: $border-light;
  }

  .cal-day-cell:not(:last-child) {
    border-right-color: $border-light;
  }
  .cal-days {
    border-color: $border-light;
    .cal-cell-row {
      border-bottom-color: $border-light;
    }
  }
  .cal-cell-top {
      min-height: 55px;
  }
  .cal-cell-content {
    padding: 0 $gap-xs $gap-xs;
  }
}

.month-day-collapsed {
  padding: $gap-xxs;
  background-color: $border-light;
}

@include media-breakpoint-down(sm) {
  .app-monthly-calendar {
    .cal-cell-content {
      padding: 0 $gap-xxs $gap-xs;
      text-align: center;
    }
    .cal-month-view .cal-day-number {
      margin: 0;
      padding: 0 $gap-xxs $gap-xs;
      float: none;
      font-size: 0.95em;
    }
  }
}
