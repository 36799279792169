.general-list-header {
  color: $gray-darken-20;
  .has-icon {
    display: flex;
    .meta-label {
      padding-top: 0.375rem;
    }
  }
  .icon {
    margin-right: 0.5rem;
    font-size: 1.375rem;
  }
}

.general-list {
  margin: 0 0 $gap-sm;
  padding: 0;
  list-style: none;
  max-height: 250px;
  overflow-y: auto;
}

.general-list-item {
  display: flex;
  padding: $gap-xs;
  border-bottom: 1px solid $border-light;
  font-size: 0.875rem;
}
