.priorities-legend {
  position: fixed;
  bottom: 0;
  left: $side-menu-width;
  right: 0;
  z-index: 50;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  padding: 0.25rem $gap-xxs;
  box-shadow: 0px -4px 8px rgba(138, 151, 158, 0.25);
  background: $white;
  .main-content-wrapper-horizontal & {
    left: 0;
    justify-content: flex-start;
  }
  &.collapsed {
    justify-content: flex-end;
    left: auto;
    border-top-left-radius: 0.5rem;
    .legend-list-wrapper {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
  .legend-label {
    flex-grow: 0;
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
    color: $gray-lighten-30;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: $gray-lighten-60;
      transition: all 0.2s ease-in-out;
    }
  }
  .icon {
    display: inline-block;
    transform: rotate(0deg);
  }
  .legend-list-wrapper {
    align-self: center;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
  }
  .legend-list {
    max-width: 100%;
    padding-left: 0.5rem;
    overflow-y: auto;
    white-space: nowrap;
  }
  &.collapsed {
    .legend-label {
      background-color: $gray-lighten-60;
    }
    .legend-list {
      width: 0px;
      overflow: hidden;
    }
    .icon {
      transform: rotate(180deg);
    }
  }
}
@include media-breakpoint-down(lg) {
  .priorities-legend {
    left: 0;
  }
}
