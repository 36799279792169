.list-group {
  margin: $gap-xs 0;
  padding: 0;
  max-height: 250px;
  overflow-y: auto;
  &.list-group-remove {
    .list-group-item{
      padding-right: 6px;
    }
  }
  &.list-group-preview {
    .list-group-item{
      cursor: pointer;
    }
  }
  .list-group-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px 12px 4px 12px;
    border: 1px solid $border;
    border-radius: 4px;
    background-color: $white;
    color: $gray-darken-20;
    font-size: 12px;
    margin-bottom: 4px;
    letter-spacing: 0.5px;
    b {
      font-family: $font-family-semibold;
    }
  }
  &.list-group-inline {
    display: flex;
  }
  .icon {
    font-size: 1rem;
  }
}

.list-group-select {
  .list-group-item {
    &:hover {
      cursor: pointer;
    }
    &.deselected {
      background-color: $gray-lighten-60;
      color: $gray-lighten-30;
    }
  }
}

.empty-list-message {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  padding: $gap-xs;
  background-color: $gray-lighten-60;
  color: $gray-lighten-30;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
} 

.list-group-count {
  color: $gray-lighten-10;
  font-size: 0.875rem;
  .counter {
    color: $blue-base;
    font-family: $font-family-semibold;
  }
}