.app-page-header {
  position: fixed;
  top: 0;
  left: $side-menu-width;
  right: 0;
  z-index: 100;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: $page-header-height;
  padding: $gap-xxs $gap-xs;
  border-bottom: 1px solid $border-light;
  background-color: $white;
  .simulate & {
    top: $simulate-header-height;
  }
  .title {
    margin: 0;
    font-family: $headings-font-family;
    font-weight: $font-family-weight;
    font-size: 18px;
    line-height: 18px;
  }
  .header-actions {
    display: flex;
    margin-left: auto;
  }
}

@include media-breakpoint-down(lg) {
  .app-page-header {
    left: 0;
    padding-left: 60px;
  }
}

@include media-breakpoint-down(sm) {
  .app-page-header {
    padding: $gap-xxs $gap-xxs $gap-xxs $gap-md;
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .btn {
      padding: 0.5rem;
      font-size: 0.75rem;
      line-height: 1;
      white-space: nowrap;
    }
    .mr-2 {
      margin-left: 5px;
      margin-right: 5px !important;
    }
  }
}
