.prompt-header {
  margin-bottom: $gap-xs;
}
.prompt-title {
  color: $red-base;
  font-size: 1.5rem;
  line-height: 2rem;
}
.prompt-body {
  max-height: 150px;
  margin-bottom: $gap-sm;
  color: $gray-darken-10;
  font-size: 0.875rem;
  white-space: pre-wrap;
  overflow: auto;
}
.prompt-footer {
  text-align: right;
}
