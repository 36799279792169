// Securly colors
$blue-base: #0073e6;
$blue-darken-10: #005dba;
$blue-darken-20: #004a94;
$blue-darken-30: #003b75;
$blue-lighten-10: #308deb;
$blue-lighten-20: #62a9f0;
$blue-lighten-30: #8dc1f4;
$blue-lighten-40: #b8d8f8;
$blue-lighten-50: #e2effc;
$blue-lighten-60: #f6fafe;
$purple-base: #645df0;
$purple-darken-10: #4542ee;
$purple-darken-20: #0020eb;
$purple-darken-30: #0020eb;
$purple-lighten-10: #847cf3;
$purple-lighten-20: #847cf3;
$purple-lighten-30: #bbb5f8;
$purple-lighten-40: #d4d0fa;
$purple-lighten-50: #eeecfd;
$purple-lighten-60: #faf9fe;
$orange-base: #b15a0c;
$orange-darken-10: #934c0f;
$orange-darken-20: #733d10;
$orange-darken-30: #5a3110;
$orange-lighten-10: #d96d04;
$orange-lighten-20: #f08b3c;
$orange-lighten-30: #faaa70;
$orange-lighten-40: #ffcaa5;
$orange-lighten-50: #ffeadb;
$orange-lighten-60: #fff9f4;
$red-base: #e11d00;
$red-darken-10: #b92002;
$red-darken-20: #911f07;
$red-darken-30: #711d08;
$red-lighten-10: #f35531;
$red-lighten-20: #fe7f5e;
$red-lighten-30: #ffa68b;
$red-lighten-40: #ffc8b7;
$red-lighten-50: #ffe9e2;
$red-lighten-60: #fff9f6;
$yellow-base: #877017;
$yellow-darken-10: #715d17;
$yellow-darken-20: #5a4915;
$yellow-darken-30: #473a14;
$yellow-lighten-10: #a48915;
$yellow-lighten-20: #c1a211;
$yellow-lighten-30: #dcba07;
$yellow-lighten-40: #f3d259;
$yellow-lighten-50: #feecbc;
$yellow-lighten-60: #fffaec;
$green-base: #008566;
$green-darken-10: #006d56;
$green-darken-20: #005646;
$green-darken-30: #004438;
$green-lighten-10: #379b7e;
$green-lighten-20: #6cb299;
$green-lighten-30: #95c6b2;
$green-lighten-40: #bddbce;
$green-lighten-50: #e4f0ea;
$green-lighten-60: #f7fbf9;
$gray-base: #567593;
$gray-darken-10: #47617b;
$gray-darken-20: #384d61;
$gray-darken-30: #2c3d4d;
$gray-darken-40: #212e3a;
$gray-darken-50: #141d24;
$gray-lighten-10: #748ea6;
$gray-lighten-20: #92a7ba;
$gray-lighten-30: #afbecc;
$gray-lighten-40: #ccd6df;
$gray-lighten-50: #eaeef2;
$gray-lighten-60: #f9fafb;
$gray-lighten-70: #fff;

// Box shadows
$elevation-1: 0px 4px 4px 0px rgba(44, 61, 77, 0.15), 0px 1px 2px 0px rgba(44, 61, 77, 0.3);
$elevation-2: 0px 1px 3px 0px rgba(44, 61, 77, 0.3), 0px 4px 8px 3px rgba(44, 61, 77, 0.15);
$elevation-3: 0px 8px 12px 6px rgba(44, 61, 77, 0.15), 0px 4px 4px 0px rgba(44, 61, 77, 0.3);
$elevation-4: 0px 10px 15px 0px rgba(44, 61, 77, 0.1);
$focus: 0px 0px 4px 0px $blue-lighten-10;
$elevation-header: 0px 4px 8px rgba(44, 61, 77, 0.15);
$elevation-footer: 0px -4px 8px rgba(44, 61, 77, 0.15);

// Color system

$border: $gray-lighten-20;
$border-light: $gray-lighten-40;
$alert-na: #c2babe;

$black: #000;
$white: #fff;

$body-bg: $gray-lighten-60;
$body-color: $gray-darken-20;
$headings-color: $gray-darken-40;
$text-muted: $gray-lighten-10;

$priority-normal: $gray-lighten-20;
$priority-normal-dark: $gray-lighten-10;
$priority-optional-roster: $orange-lighten-10;
$priority-optional-roster-dark: $orange-base;
$priority-regular-roster: $purple-lighten-40;
$priority-regular-roster-dark: $purple-lighten-30;
$priority-priority-roster: $purple-lighten-10;
$priority-priority-roster-dark: $purple-base;
$priority-teacher-request: $blue-lighten-10;
$priority-teacher-request-dark: $blue-base;
$priority-advertise: $green-lighten-10;
$priority-advertise-dark: $green-base;
$priority-admin: $yellow-lighten-30;
$priority-admin-dark: $yellow-lighten-20;

$priority-catchall: $green-lighten-30;
$priority-catchall-dark: $green-lighten-10;
$priority-lunch: $orange-lighten-40;
$priority-lunch-dark: $orange-lighten-10;

// Metrics

$gap-xxs: 0.5rem;
$gap-xs: 1rem;
$gap-sm: 2rem;
$gap-md: 3rem;
$gap-lg: 4rem;
$gap-xl: 5rem;

$side-menu-width: 250px;
$page-header-height: 50px;
$page-footer-height: 60px;
$simulate-header-height: 50px;
$calendar-nav-height: 67px;
$calendar-nav-height-mobile: 111px;

// Typography

$font-family-thin: 'MetropolisThin', sans-serif;
$font-family-thin-italic: 'MetropolisThinItalic', sans-serif;
$font-family-extralight: 'MetropolisExtraLight', sans-serif;
$font-family-extralight-italic: 'MetropolisExtraLightItalic', sans-serif;
$font-family-light: 'MetropolisLight', sans-serif;
$font-family-light-italic: 'MetropolisLightItalic', sans-serif;
$font-family-regular: 'MetropolisRegular', sans-serif;
$font-family-regular-italic: 'MetropolisRegularItalic', sans-serif;
$font-family-medium: 'MetropolisMedium', sans-serif;
$font-family-medium-italic: 'MetropolisMediumItalic', sans-serif;
$font-family-semibold: 'MetropolisSemiBold', sans-serif;
$font-family-semibold-italic: 'MetropolisSemiBoldItalic', sans-serif;
$font-family-bold: 'MetropolisBold', sans-serif;
$font-family-bold-italic: 'MetropolisBoldItalic', sans-serif;
$font-family-extrabold: 'MetropolisExtraBold', sans-serif;
$font-family-extrabold-italic: 'MetropolisExtraBoldItalic', sans-serif;
$font-family-black: 'MetropolisBlack', sans-serif;
$font-family-black-italic: 'MetropolisBlackItalic', sans-serif;

$font-family-weight: normal;

$font-weight-lighter: $font-family-weight;
$font-weight-light: $font-family-weight;
$font-weight-normal: $font-family-weight;
$font-weight-bold: $font-family-weight;
$font-weight-bolder: $font-family-weight;

$font-family-base: $font-family-regular;
$headings-font-family: $font-family-semibold;
$headings-font-weight: $font-family-weight;

$line-height-base: 1.6;

// Boostrap colors

$primary: $blue-base;
$secondary: $gray-darken-50;
$success: $green-lighten-20;
$info: $blue-lighten-30;
$warning: $orange-lighten-20;
$danger: $red-lighten-10;
$light: $gray-darken-20;
$dark: $gray-darken-50;

// Bootstrap components

$grid-gutter-width: 16px;

$border-radius: 4px;

$input-btn-focus-box-shadow: none;
$input-btn-font-size: 0.875rem;
$input-btn-line-height: 1.25rem;

$btn-font-family: $font-family-semibold;
$btn-font-weight: $font-family-weight;
$btn-padding-y: 0.5rem;
$btn-padding-x: 1.25rem;
$btn-border-radius: 35px;
$btn-border-width: 2px;
$btn-font-size: 0.875rem;
$btn-line-height: 1.25rem;

$btn-border-radius-sm: 35px;
$btn-font-size-sm: 0.75rem;
$btn-padding-y-sm: 0.375rem;
$btn-padding-x-sm: 1rem;
$btn-line-height-sm: 1rem;

$input-padding-x: 1rem;
$input-padding-y: 0.563rem;
$input-border-color: $border;
$input-color: $gray-darken-20;
$input-height-inner: auto;
$input-height-inner-half: auto;
$input-height-inner-quarter: auto;
$input-height: auto;
$input-height-sm: auto;
$input-height-lg: auto;
$input-placeholder-color: $gray-lighten-10;
$input-font-family: $font-family-regular;
$input-font-weight: $font-family-weight;

$form-group-margin-bottom: 1rem;

$custom-control-indicator-size: 1.25rem;
$custom-control-indicator-border-width: 2px;

$custom-checkbox-indicator-border-radius: 4px;
$custom-control-indicator-checked-color: $blue-base;
$custom-control-indicator-checked-bg: $white;
$custom-control-indicator-checked-border-color: $gray-lighten-10;

$custom-control-label-color: $gray-darken-50;

$modal-sm: 320px;
$modal-md: 580px;
$modal-lg: 760px;
$modal-xl: 990px;

$zindex-modal-backdrop: 1054;
$zindex-modal: 1055;
$modal-header-padding-y: 1.5rem;
$modal-header-padding-x: 1.5rem;
$modal-backdrop-opacity: 0.8;
$modal-content-border-width: 0;

$dropdown-border-radius: 8px;
$dropdown-border-color: $border-light;
$dropdown-item-padding-y: 0.5rem;
$dropdown-box-shadow: $elevation-1;
$dropdown-font-size: 0.875rem;
$dropdown-color: $gray-darken-50;
$dropdown-link-hover-color: $gray-darken-50;
$dropdown-link-hover-bg: $gray-lighten-60;
$dropdown-link-active-color: $blue-darken-10;
$dropdown-link-active-bg: $blue-lighten-50;
