/* Apply styles to Firefox Only */
@-moz-document url-prefix() {
  :root {
    scrollbar-width: thin;
    scrollbar-color: $gray-lighten-30 $gray-lighten-50;
  }
}

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 20px;
  box-shadow: none;
  background: $gray-lighten-50;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: none;
  background: $gray-lighten-30;
}
