.flex-period-header {
  position: relative;
  margin-bottom: $gap-xxs;
  padding: 0.75rem;
  border-radius: 8px;
  border: 2px solid $white;
  background: $white;
  text-align: center;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: $gray-lighten-50;
    transition: all 0.2s ease-in-out;
  }
  .icon {
    position: absolute;
    top: 50%;
    right: $gap-xs;
    margin-top: -12px;
    font-size: 24px;
    transition: all 0.2s ease-in-out;
    .panel-open & {
      transform: rotate(180deg);
      transition: all 0.2s ease-in-out;
    }
  }
  .flex-name {
    font-size: 1.25rem;
  }
  .flex-time {
    font-size: 0.875rem;
  }
}
.flex-name {
  color: $gray-darken-20;
  font-family: $font-family-bold;
  font-size: 1rem;
  line-height: 1;
  
}
.flex-time {
  color: $gray-lighten-10;
  font-size: 0.75rem;
  line-height: 1.6;
}
.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;

  border-radius: 100%;  
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}