.ngx-datatable {
  width: 100%;
}
.ngx-datatable.app-datatable {
  // overflow-x: visible !important;
  // overflow-y: visible !important;
  &.app-datatable-hover {
    .datatable-body-row {
      &:hover {
        background-color: $blue-lighten-60;
      }
    }
  }
  .datatable-scroll {
    display: inherit !important;
  }
  .datatable-header {
    border-bottom: 1px solid $border-light;
    .datatable-header-inner {
      height: 100%;
      .datatable-row-center {
        align-items: flex-end;
      }
    }
    .datatable-header-cell {
      height: 100%;
      margin-bottom: $gap-xxs;
      padding: $gap-xxs;
      color: $gray-darken-20;
      font-family: $font-family-bold;
      font-size: 0.6875rem;
      font-weight: $font-family-weight;
      line-height: 1;
      vertical-align: bottom;
      border-right: 1px solid $border-light;
      .cell-text-wrap {
        text-wrap: wrap;
      }
      &:last-child {
        border-right: none;
      }
      .datatable-header-cell-label {
        white-space: pre-wrap;
      }
      .datatable-header-cell-template-wrap {
        display: flex;
        align-items: end;
      }
    }
  }
  .datatable-group-header {
    padding: $gap-xs;
    border-bottom: 1px solid $border-light;
    background-color: $blue-lighten-60;
    color: $gray-darken-20;
  }
  .datatable-tree-button {
    display: none;
  }
  .datatable-body-cell {
    overflow-y: visible !important;
    overflow-x: visible !important;
    &.datatable-body-cell-missing {
      background-color: $red-lighten-60;
    }
  }
  .datatable-body {
    color: $gray-darken-20;
    font-size: 0.75rem;
    line-height: 0.875rem;

    .group-missing-header {
      display: flex;
      justify-content: center;
      margin-bottom: -1px;
      padding: 1rem;
      border-top: 1px solid $border-light;
      border-bottom: 1px solid $red-lighten-50;
      background-color: $red-lighten-50;
      color: $red-base;
    }
    .datatable-row-wrapper {
      &:last-child {
        .ng-dropdown-panel.ng-select-bottom {
          top: auto;
          bottom: 0;
        }
      }
      &:only-child {
        .ng-dropdown-panel.ng-select-bottom {
          top: 50%;
          bottom: auto;
        }
      }
    }
    .datatable-body-row {
      border-bottom: 1px solid $border-color;
      transition: all 0.2s ease;
      .datatable-body-cell-label {
        padding: $gap-xs $gap-xxs;
        :first-child {
          word-break: break-all;
        }
      }
      &.datatable-body-row-missing {
        border-bottom: 1px solid $red-lighten-50;
      }
      &.datatable-body-row-catchall {
        background-color: rgba($priority-catchall, 0.3);
        .activity-type {
          color: $priority-catchall-dark;
          text-transform: uppercase;
        }
      }
      &.datatable-body-row-lunch {
        background-color: rgba($priority-lunch, 0.3);
        .activity-type {
          color: $priority-lunch-dark;
          text-transform: uppercase;
        }
      }
      &.datatable-body-row-cut {
        // border-bottom: 1px solid $red-lighten-30;
        // background-color: rgba($red-lighten-10, 0.3);
        background-color: $white;
      }
      &.datatable-body-row-absent {
        // border-bottom: 1px solid $yellow-lighten-40;
        // background-color: rgba($yellow-lighten-40, 0.3);
        background-color: $white;
      }
      &.datatable-body-row-unexcused {
        // border-bottom: 1px solid $gray-lighten-10;
        // background-color: rgba($gray-lighten-10, 0.2);
        background-color: $white;
      }
      &.datatable-body-row-archived {
        // background-color: rgba($gray-lighten-10, 0.2);
        background-color: $white;
      }
    }
    .datatable-body-cell-label {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .datatable-small {
      font-size: 0.625rem;
      line-height: 0.75rem;
    }
    .datatable-icon {
      font-size: 1.25rem;
    }
  }
  .datatable-checkbox {
    margin: 0;
    input[type='checkbox']:before {
      border-color: $border;
      border-radius: 4px;
      transition: none;
    }
    input[type='checkbox']:checked:before {
      border-color: $blue-base;
      border-radius: 0;
    }
  }
  .align-right {
    .datatable-body-cell-label {
      justify-content: flex-end;
    }
  }
  .col-xs {
    width: 50px;
    max-width: 50px;
  }
  .col-sm {
    width: 100px;
    max-width: 100px;
  }
  .col-md {
    width: 150px;
    max-width: 150px;
  }
  .col-lg {
    width: 200px;
    max-width: 200px;
  }
  .col-title {
    color: $gray-darken-20;
  }
  .datatable-footer {
    .datatable-pager {
      .pager {
        padding: 0 0.5rem;
        border-radius: 20px;
        background: $gray-lighten-60;
      }
      li {
        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        a {
          display: block;
          width: 2rem;
          height: 2rem;
          padding: 0.25rem 0;
          border-radius: 100%;
          color: $blue-lighten-30;
          font-size: 0.75rem;
          text-align: center;
          text-decoration: none;
          line-height: 1.5rem;
          transition: all 0.2s ease-in-out;
          &:hover {
            background: $white;
            color: $gray-darken-50;
            transition: all 0.2s ease-in-out;
          }
          .datatable-icon-left,
          .datatable-icon-skip,
          .datatable-icon-right,
          .datatable-icon-prev {
            font-size: 1rem;
            line-height: 1rem;
          }
        }
        &.active {
          a {
            background: $blue-lighten-30;
            color: $white;
          }
        }
      }
      .pages {
        a {
          color: $blue-base;
        }
      }
      .disabled {
        a {
          color: $gray-lighten-10;
          &:hover {
            background: $gray-lighten-10;
            color: $gray-lighten-30;
            transition: all 0.2s ease-in-out;
          }
        }
      }
      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 18px;
        line-height: 27px;
        padding: 0 3px;
        vertical-align: middle;
      }
    }
    .page-count {
      padding: 1rem 0.75rem 0.9375rem;
      color: $blue-base;
      font-size: 0.875rem;
      font-family: $font-family-semibold;
    }
  }
  .progress-linear {
    position: sticky;
    top: 0;
    display: block;
    width: 100%;
    height: 5px;
    padding: 0;
    margin: 0;
    .container {
      position: relative;
      display: block;
      width: 100%;
      max-width: 100%;
      height: 5px;
      border-radius: $border-radius $border-radius 0 0;
      background-color: $border;
      overflow: hidden;
      transform: translate(0, 0) scale(1, 1);
      .bar {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 5px;
        background-color: $blue-base;
        animation: progress-animation 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
        transition: all 0.2s linear;
      }
    }
  }
  .dt-label {
    font-size: 0.625rem;
  }
  &.app-datatable-attendance {
    scale: 0.99;
    .datatable-group-header {
      padding: 0;
    }
  }
}

.datatable-type-badge {
  padding: 0.25rem;
  border-radius: 4px;
  background-color: $blue-lighten-50;
  color: $gray-base;
  word-break: break-word !important;
}

@keyframes progress-animation {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}

.custom-activities {
  .ngx-datatable.app-datatable {
    .datatable-header-cell {
      padding: $gap-sm $gap-xxs $gap-xxs $gap-xxs;
      color: $gray-darken-20;
      font-size: 0.6875rem;
      font-family: $font-family-semibold;
      vertical-align: bottom;
    }
    .datatable-body .datatable-body-row {
      margin: 0.125rem 0;
      border-bottom: none;
      border-radius: 35px;
      background-color: $border;
      color: $gray-darken-50;
      &:hover {
        cursor: pointer;
      }
      .datatable-body-cell-label {
        padding: $gap-xxs;
      }
      .activity-type {
        color: $gray-darken-50;
      }
      .activity-title {
        color: $gray-darken-50;
      }
      &.priority-ama {
        background-color: $priority-admin;
        &:hover {
          background-color: darken($priority-admin, 10%);
        }
      }
      &.priority-pr {
        background-color: $priority-priority-roster;
        &:hover {
          background-color: darken($priority-priority-roster, 10%);
        }
      }
      &.priority-tr {
        background-color: $priority-teacher-request;
        &:hover {
          background-color: darken($priority-teacher-request, 10%);
        }
      }
      &.priority-rr {
        background-color: $priority-regular-roster;
        &:hover {
          background-color: darken($priority-regular-roster, 10%);
        }
      }
      &.priority-or {
        background-color: $priority-optional-roster;
        &:hover {
          background-color: darken($priority-optional-roster, 10%);
        }
      }
      &.priority-aa {
        background-color: $priority-advertise;
        &:hover {
          background-color: darken($priority-advertise, 10%);
        }
      }
      &.priority-na {
        background-color: $priority-normal;
        &:hover {
          background-color: darken($priority-normal, 10%);
        }
      }
    }
    .datatable-header {
      border-bottom: none;
    }
    .datatable-footer {
      border-top: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .ngx-datatable.app-datatable {
    .datatable-footer {
      .datatable-footer-inner {
        flex-wrap: wrap;
      }
      .selected-count {
        .page-count,
        .datatable-pager {
          flex: 0 0 100%;
          text-align: center;
        }
      }
    }
  }
}
