.meta-bar {
  background: $blue-lighten-50;
  color: $gray-base;
  .title {
    color: $gray-darken-50;
    font-size: 1rem;
    font-family: $font-family-semibold;
    line-height: 1;
  }
  .sub-title {
    font-size: 0.75rem;
    font-family: $font-family-medium;
    letter-spacing: 1px;
    line-height: 1.3;
    text-transform: uppercase;
  }
}
