@font-face {
    font-family: 'ftm-icons';
    src:
      url('../assets/fonts/ftm-icons.ttf?j8314a') format('truetype'),
      url('../assets/fonts/ftm-icons.woff?j8314a') format('woff'),
      url('../assets/fonts/ftm-icons.svg?j8314a#ftm-icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="ftmi-"], [class*=" ftmi-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'ftm-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .ftmi-unlocked:before {
    content: "\e957";
  }
  .ftmi-seats:before {
    content: "\e958";
  }
  .ftmi-locked:before {
    content: "\e959";
  }
  .ftmi-unarchive:before {
    content: "\e956";
  }
  .ftmi-sort:before {
    content: "\e953";
  }
  .ftmi-sort-down:before {
    content: "\e954";
  }
  .ftmi-sort-up:before {
    content: "\e955";
  }
  .ftmi-barcode:before {
    content: "\e952";
  }
  .ftmi-flex-period:before {
    content: "\e94c";
  }
  .ftmi-log:before {
    content: "\e94d";
  }
  .ftmi-priority:before {
    content: "\e94e";
  }
  .ftmi-school:before {
    content: "\e94f";
  }
  .ftmi-types:before {
    content: "\e950";
  }
  .ftmi-users:before {
    content: "\e951";
  }
  .ftmi-a-b-polarity:before {
    content: "\e93b";
  }
  .ftmi-activities:before {
    content: "\e93c";
  }
  .ftmi-attendance-activity:before {
    content: "\e93d";
  }
  .ftmi-cancellation:before {
    content: "\e93e";
  }
  .ftmi-custlist:before {
    content: "\e93f";
  }
  .ftmi-dashboard:before {
    content: "\e940";
  }
  .ftmi-excluded-students:before {
    content: "\e941";
  }
  .ftmi-full-attendance:before {
    content: "\e942";
  }
  .ftmi-my-schedule:before {
    content: "\e943";
  }
  .ftmi-restricted-student:before {
    content: "\e944";
  }
  .ftmi-room-use:before {
    content: "\e945";
  }
  .ftmi-roster:before {
    content: "\e946";
  }
  .ftmi-student:before {
    content: "\e947";
  }
  .ftmi-student-attendance:before {
    content: "\e948";
  }
  .ftmi-student-search:before {
    content: "\e949";
  }
  .ftmi-substitute:before {
    content: "\e94a";
  }
  .ftmi-teacher-attendance:before {
    content: "\e94b";
  }
  .ftmi-announcement:before {
    content: "\e900";
  }
  .ftmi-archive:before {
    content: "\e901";
  }
  .ftmi-arrow-down:before {
    content: "\e902";
  }
  .ftmi-arrow-left:before {
    content: "\e903";
  }
  .ftmi-arrow-right:before {
    content: "\e904";
  }
  .ftmi-arrow-up:before {
    content: "\e905";
  }
  .ftmi-calendar:before {
    content: "\e906";
  }
  .ftmi-check:before {
    content: "\e907";
  }
  .ftmi-chevron-down:before {
    content: "\e908";
  }
  .ftmi-chevron-left:before {
    content: "\e909";
  }
  .ftmi-chevron-right:before {
    content: "\e90a";
  }
  .ftmi-chevron-up:before {
    content: "\e90b";
  }
  .ftmi-clock:before {
    content: "\e90c";
  }
  .ftmi-cog:before {
    content: "\e90d";
  }
  .ftmi-comment:before {
    content: "\e90e";
  }
  .ftmi-copy:before {
    content: "\e90f";
  }
  .ftmi-cross:before {
    content: "\e910";
  }
  .ftmi-delete:before {
    content: "\e911";
  }
  .ftmi-directions:before {
    content: "\e912";
  }
  .ftmi-directory:before {
    content: "\e913";
  }
  .ftmi-disabled:before {
    content: "\e914";
  }
  .ftmi-download:before {
    content: "\e915";
  }
  .ftmi-edit:before {
    content: "\e916";
  }
  .ftmi-email:before {
    content: "\e917";
  }
  .ftmi-enabled:before {
    content: "\e918";
  }
  .ftmi-exclude:before {
    content: "\e919";
  }
  .ftmi-file:before {
    content: "\e91a";
  }
  .ftmi-filter:before {
    content: "\e91b";
  }
  .ftmi-group:before {
    content: "\e91c";
  }
  .ftmi-help:before {
    content: "\e91d";
  }
  .ftmi-impersonate:before {
    content: "\e91e";
  }
  .ftmi-info:before {
    content: "\e91f";
  }
  .ftmi-location:before {
    content: "\e920";
  }
  .ftmi-logout:before {
    content: "\e921";
  }
  .ftmi-menu:before {
    content: "\e922";
  }
  .ftmi-minus:before {
    content: "\e923";
  }
  .ftmi-more:before {
    content: "\e924";
  }
  .ftmi-notification:before {
    content: "\e925";
  }
  .ftmi-outline-cross:before {
    content: "\e926";
  }
  .ftmi-play:before {
    content: "\e927";
  }
  .ftmi-plus:before {
    content: "\e928";
  }
  .ftmi-preview:before {
    content: "\e929";
  }
  .ftmi-print:before {
    content: "\e92a";
  }
  .ftmi-qr:before {
    content: "\e92b";
  }
  .ftmi-refresh:before {
    content: "\e92c";
  }
  .ftmi-schedule:before {
    content: "\e92d";
  }
  .ftmi-search:before {
    content: "\e92e";
  }
  .ftmi-shield-keyhole:before {
    content: "\e92f";
  }
  .ftmi-shield-keyhole-add:before {
    content: "\e930";
  }
  .ftmi-smile:before {
    content: "\e931";
  }
  .ftmi-sorting:before {
    content: "\e932";
  }
  .ftmi-teacher:before {
    content: "\e933";
  }
  .ftmi-upload:before {
    content: "\e934";
  }
  .ftmi-user:before {
    content: "\e935";
  }
  .ftmi-user-allowed:before {
    content: "\e936";
  }
  .ftmi-user-forbidden:before {
    content: "\e937";
  }
  .ftmi-user-graduation:before {
    content: "\e938";
  }
  .ftmi-user-share:before {
    content: "\e939";
  }
  .ftmi-user-shared:before {
    content: "\e93a";
  }
  .ftmi-default-activity:before {
    content: "\e960";
  }