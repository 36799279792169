.menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  width: $side-menu-width;
  max-height: 100%;
  background: $white;
  color: $white;
  .menu-header {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: $page-header-height;
    padding: 0 $gap-xs;
    border-bottom: 1px solid $border-light;
  }
  .school-header {
    flex-grow: 0;
    flex-shrink: 0;
    padding: $gap-xxs;
    .btn-group {
      position: relative;
    }

    .school-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-color: $black;
      text-align: center;
      overflow: hidden;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .school-year-label {
      margin-left: $gap-xs;
      font-family: $font-family-semibold;
      font-size: 11px;
      line-height: 16px;
    }
  }
  .school-header-horizontal {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0.625rem;
    .btn-group {
      position: relative;
    }
    .school-logo-horizontal {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background-color: $black;
      text-align: center;
      overflow: hidden;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .school-name {
    margin-left: 0.375rem;
    font-family: $font-family-semibold;
    font-size: 0.688rem;
    line-height: 1rem;
  }
  .school-brand {
    display: flex;
    align-items: center;
    color: $body-color;
    white-space: unset;
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
  .btn-settings {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $gap-xxs;
    padding: 0.25rem;
    border-radius: 4px;
    border-width: 1px;
    border-color: $border-light;
    color: $blue-base;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 1;
    &.btn-settings-back {
      justify-content: flex-start;
      border-color: transparent;
      .icon {
        margin-right: 0.5rem;
      }
    }
    .icon {
      margin-right: 0.25rem;
      font-size: 1rem;
      line-height: 1;
    }
  }
  .btn-school-year {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $gap-xxs;
    padding: $gap-xxs;
    border-radius: 4px;
    border-color: $gray-lighten-60;
    background: $gray-lighten-60;
    color: $body-color;
    font-size: 0.688rem;
    line-height: 1;
    text-transform: uppercase;
  }
  .user-dropdown {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 0.8125rem;
  }
  .menu-nav {
    margin: 0;
    padding: $gap-xxs;
    flex-grow: 1;
    flex-shrink: 1;
    list-style: none;
    overflow-y: auto;
  }
  .menu-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .menu-list-separator {
    hr {
      height: 1px;
      border-width: 0;
      color: $border-light;
      background-color: $border-light;
    }
  }
  .menu-list-item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: $gap-xxs;
    color: $gray-darken-20;
    font-style: normal;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 4px;
      bottom: 4px;
      display: block;
      width: 4px;
      border-radius: 2px;
      background-color: transparent;
    }
    &:hover {
      text-decoration: none;
      cursor: pointer;
      &:before {
        background-color: $purple-darken-30;
      }
    }
    &.active {
      &:before {
        background-color: $purple-darken-30;
      }
    }
    .icon {
      margin-right: $gap-xxs;
      color: $purple-darken-30;
      font-size: 1.5rem;
    }
  }
  .menu-list-link {
    border: none;
    border-radius: 8px;
    font-family: $font-family-medium;
    font-size: 15px;
    line-height: 16px;
    transition: all 0.3s ease-out;
    &.active,
    &:hover {
      transition: all 0.3s ease-out;
    }
    &.active {
      background: $blue-lighten-50;
      color: $gray-darken-20;
    }
    &:hover {
      background: $gray-lighten-60;
    }
  }
  .logout {
    display: block;
    margin: 0 $gap-xxs;
    border-radius: 35px;
    background-color: $blue-lighten-50;
    color: $gray-base;
  }

  &.menu-vertical {
    box-shadow: $elevation-2;
    .menu-list-parent {
      position: relative;
      .btn.menu-list-item {
        justify-content: space-between;
        align-items: center;
        font-family: $font-family-medium;
        font-size: 0.813rem;
        letter-spacing: 1px;
        line-height: 1;
        text-transform: uppercase;
        &:before {
          display: none;
        }
      }
      .icon-dd {
        color: $body-color;
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
      }
      .expanded-parent {
        color: $purple-darken-30;
        .icon-dd {
          transform: rotate(-180deg);
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .menu-utility {
      width: $side-menu-width;
      padding: $gap-xxs;
      background-color: $white;
      .user-utility {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $gap-xxs;
        border-radius: 8px;
        background-color: $blue-lighten-50;
        .btn-group {
          flex-grow: 1;
        }
      }
      .user-name {
        display: block;
        max-width: 105px;
        padding: 0 $gap-xxs;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.menu-horizontal {
    right: 0;
    bottom: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: $page-header-height;
    max-height: $page-header-height;
    box-shadow: $elevation-header;
    border-bottom: 1px solid $border-light;
    .simulate & {
      top: $simulate-header-height;
    }
    .user-utility {
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: $gap-xxs;
    }
    .menu-nav-wrapper {
      flex-direction: row;
      padding-bottom: 0;
    }
    .menu-nav {
      display: flex;
      align-items: center;
      padding: 0;
      overflow-y: visible;
    }
    .menu-header {
      border-bottom: none;
    }
    .menu-list-child {
      .menu-list-item {
        padding: $gap-xxs;
        border-radius: 4px;
        font-size: 13px;
        line-height: 16px;
        &.active {
          background: $blue-lighten-50;
          color: $gray-darken-20;
        }
      }
    }
    .user-dropdown {
      .btn {
        display: flex;
        align-items: center;
        padding: 0.5rem;
      }
      .icon {
        font-size: 1.313rem;
      }
      .user-name {
        margin-left: 0.375rem;
        font-family: $font-family-semibold;
        font-size: 0.688rem;
        line-height: 1rem;
      }
    }
  }
}

.mobile-menu-btn {
  display: none;
}
.dd-menu {
  position: relative;
}

.btn-notifications {
  position: relative;
  padding: 0.25rem 0.375rem;
  color: $primary;
  font-size: 1.5rem;
  &:hover {
    background-color: $dark;
    color: $white;
  }
  .unread {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 1rem;
    height: 1rem;
    padding: 0.125rem;
    border-radius: 10px;
    background-color: $red;
    color: $white;
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-align: center;
  }
}

@include media-breakpoint-down(lg) {
  .menu {
    display: none;
    &.menu-horizontal {
      .menu-nav {
        display: none;
      }
      .menu-header {
        padding-left: 60px;
      }
    }
    &.show {
      .simulate & {
        top: $simulate-header-height;
      }
      &.menu-vertical {
        display: block;
        max-height: 100%;
        padding-top: $page-header-height;
        box-shadow: 4px 0px 8px rgba(138, 151, 158, 0.5);
        overflow: auto;
        .menu-header {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 1050;
          padding-left: 60px;
          background-color: $white;
        }
      }
      &.menu-horizontal {
        display: flex;
        .menu-nav {
          position: fixed;
          top: $page-header-height;
          bottom: 0;
          left: 0;
          z-index: 1000;
          width: 300px;
          display: block;
          padding: $gap-xs;
          box-shadow: 4px 8px 8px rgba(138, 151, 158, 0.5);
          background-color: $white;
          overflow-y: auto;
          .simulate & {
            top: $page-header-height + $simulate-header-height;
          }
          .menu-list-item {
            margin: $gap-xs 0;
          }
          .dropdown-main-menu {
            width: 100%;
            padding-left: $gap-xs;
            box-shadow: none;
            border: none;
            border-radius: 0;
            background: none;
            .menu-list-item {
              margin: $gap-xxs 0;
            }
          }
        }
      }
    }
  }
  .mobile-menu-btn {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1010;
    display: inline-block;
    margin-left: auto;
    padding: 0.75rem;
    cursor: pointer;
    .bar-top,
    .bar-middle,
    .bar-bottom {
      width: 25px;
      height: 3px;
      background-color: $blue-darken-10;
      margin: 4px 0;
      transition: 0.4s;
    }
    &.open {
      .bar-top {
        transform: rotate(-45deg) translate(-5px, 5px);
      }
      .bar-middle {
        opacity: 0;
      }
      .bar-bottom {
        transform: rotate(45deg) translate(-5px, -5px);
      }
    }
    .simulate & {
      top: $simulate-header-height;
    }
    .simulate & {
      top: $simulate-header-height;
    }
  }
}

@include media-breakpoint-down(md) {
  .menu {
    &.menu-horizontal {
      .school-header-horizontal {
        .school-name {
          display: none;
        }
      }

      #dropdown-basic {
        inset: 100% 0 auto auto !important;
      }

      #dropdown-school-horizontal {
        inset: 100% -150% auto auto !important;
      }

      .menu-utility {
        .user-name {
          display: none;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .menu {
    &.menu-horizontal {
      &.show {
        .menu-nav {
          right: 0;
          width: 100%;
        }
      }
    }
    &.menu-vertical {
      right: 0;
      width: 100%;
      .menu-utility {
        right: 0;
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .menu {
    .menu-nav-wrapper {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      padding-bottom: 75px;
    }
    &.menu-vertical {
      .menu-utility {
        position: fixed;
        left: 0;
        bottom: 0;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .menu {
    display: flex;
    flex-direction: column;
    &.menu-horizontal {
      flex-direction: row;
    }
  }
}
