// .modal-content {
//   box-shadow: 0 0.25rem 1.5rem rgba($black, 0.25);
// }
.prompt-modal .modal-content {
  position: relative;
}
.modal-title {
  font-family: $font-family-medium;
  font-weight: $font-family-weight;
  font-size: 1.5rem;
  line-height: 2rem;
}
.modal-body {
  padding: 1.5rem;
}
.modal-body-negative {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.modal-body-negative-top {
  margin-top: -1.5rem;
}
.modal-footer {
  justify-content: right;
  padding: 0 1.5rem 1.5rem;
}

.info-card-modal {
  .modal-header {
    padding: $gap-sm $gap-sm 0 $gap-md;
  }
  .modal-body {
    padding: 0 $gap-md;
  }
  .modal-footer {
    justify-content: center;
    padding: $gap-xs $gap-md $gap-sm;
  }
}
.modal-title-centered {
  flex-grow: 1;
  text-align: center;
}

.modal-header-icon {
  font-size: 3rem;
}

.prompt-modal {
  padding: 1.5rem;
  border-radius: 8px;
  background-color: $white;
  .modal-content {
    background-color: $white;
  }
}
@include media-breakpoint-up(sm) {
  .prompt-modal .modal-content {
    min-width: 300px !important;
  }
}
@include media-breakpoint-down(sm) {
  .info-card-modal {
    .modal-header {
      padding: $gap-sm $gap-sm 0;
    }
    .modal-body {
      padding: 0 $gap-sm;
    }
    .modal-footer {
      padding: $gap-sm;
    }
  }
}

.modal-sm {
  max-width: 379px;
}
