@font-face {
  font-family: 'MetropolisBlack';
      src: url('../assets/fonts/Metropolis/Metropolis-Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
    }

@font-face {
  font-family: 'MetropolisBlackItalic';
      src: url('../assets/fonts/Metropolis/Metropolis-BlackItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
    }

@font-face {
  font-family: 'MetropolisBold';
      src: url('../assets/fonts/Metropolis/Metropolis-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
    }

@font-face {
  font-family: 'MetropolisBoldItalic';
      src: url('../assets/fonts/Metropolis/Metropolis-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
    }

@font-face {
  font-family: 'MetropolisExtraBold';
      src: url('../assets/fonts/Metropolis/Metropolis-ExtraBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
    }

@font-face {
  font-family: 'MetropolisExtraBoldItalic';
      src: url('../assets/fonts/Metropolis/Metropolis-ExtraBoldItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
    }

@font-face {
  font-family: 'MetropolisExtraLight';
      src: url('../assets/fonts/Metropolis/Metropolis-ExtraLight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
    }

@font-face {
  font-family: 'MetropolisExtraLightItalic';
      src: url('../assets/fonts/Metropolis/Metropolis-ExtraLightItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
    }

@font-face {
  font-family: 'MetropolisLight';
      src: url('../assets/fonts/Metropolis/Metropolis-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
    }

@font-face {
  font-family: 'MetropolisLightItalic';
      src: url('../assets/fonts/Metropolis/Metropolis-LightItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
    }

@font-face {
  font-family: 'MetropolisMedium';
      src: url('../assets/fonts/Metropolis/Metropolis-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
    }

@font-face {
  font-family: 'MetropolisMediumItalic';
      src: url('../assets/fonts/Metropolis/Metropolis-MediumItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
    }

@font-face {
  font-family: 'MetropolisRegular';
      src: url('../assets/fonts/Metropolis/Metropolis-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
    }

@font-face {
  font-family: 'MetropolisRegularItalic';
      src: url('../assets/fonts/Metropolis/Metropolis-RegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
    }

@font-face {
  font-family: 'MetropolisSemiBold';
      src: url('../assets/fonts/Metropolis/Metropolis-SemiBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
    }

@font-face {
  font-family: 'MetropolisSemiBoldItalic';
      src: url('../assets/fonts/Metropolis/Metropolis-SemiBoldItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
    }

@font-face {
  font-family: 'MetropolisThin';
      src: url('../assets/fonts/Metropolis/Metropolis-Thin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
    }

@font-face {
  font-family: 'MetropolisThinItalic';
      src: url('../assets/fonts/Metropolis/Metropolis-ThinItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
    }
