label {
  .app-input &,
  .app-input-sm &,
  .app-input-inline & {
    margin-bottom: 0.25rem;
    color: $gray-darken-20;
    font-family: $font-family-medium;
    font-weight: $font-family-weight;
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

.ng-select .ng-select-container {
  border-color: $border;
  .app-input-sm & {
    height: 28px;
    min-height: 24px;
    line-height: 1;
  }
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: $border;
}
.ng-dropdown-panel {
  min-width: 100%;
  width: auto !important;
  &.ng-select-bottom {
    border-color: $border;
    box-shadow: $elevation-1;
    z-index: $zindex-modal;
  }
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $dropdown-link-hover-bg;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 0.875rem;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: $gray-lighten-10;
}

.app-input {
  .ng-select {
    border-radius: $border-radius;
    border: 1px solid $border;
    background-color: $white;
  }

  .ng-select {
    padding: 1px 8px 1px 4px;
    .ng-select-container {
      border: none;
      &:hover {
        box-shadow: none;
      }
      .ng-value-container .ng-input > input {
        &:hover {
          cursor: pointer;
        }
      }
    }
    &.ng-select-multiple {
      .ng-select-container {
        .ng-value-container {
          .ng-value {
            border: 1px solid $border;
            background-color: transparent;
            color: $gray-darken-50;
            font-size: 12px;
            font-family: $font-family-medium;
            line-height: 14px;
            .ng-value-icon.left {
              border-color: $border;
            }
          }
        }
      }
      .ng-option {
        margin: 2px 4px;
        color: $gray-darken-20;
        font-size: 12px;
        font-family: $font-family-medium;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: $gray-lighten-60;
          transition: all 0.2s ease-in-out;
          .ng-option-label {
            color: $gray-darken-20;
          }
        }
        &.ng-option-selected {
          background-color: $blue-lighten-30;
          transition: all 0.2s ease-in-out;
          .ng-option-label {
            color: $gray-darken-50;
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }
  }

  &.datepicker-unit {
    position: relative;
    &:after {
      content: '\e906';
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;
      display: block;
      color: $blue-base;
      font-size: 1.5rem;
      font-family: 'ftm-icons' !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      .form-error & {
        color: $red-lighten-10;
      }
    }
  }
}

.app-input-sm {
  .form-control {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    line-height: 1;
  }
}

.app-input-inline {
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid $border;
  > label {
    flex-grow: 0;
  }
  > * {
    flex-grow: 1;
  }
  .ng-select {
    .ng-select-container {
      font-size: 0.875rem;
      color: $gray-darken-50;
      border: none;
      background-color: transparent;
    }
    .ng-spinner-loader {
      border-left-color: $blue-base;
    }
    &.ng-select-disabled {
      .ng-select-container {
        box-shadow: none;
        cursor: not-allowed;
        .ng-input > input {
          cursor: not-allowed;
        }
        .ng-value-container {
          cursor: not-allowed;
          .ng-placeholder {
            color: $gray-darken-20;
            cursor: not-allowed;
          }
        }
      }
      .ng-arrow {
        border-color: #cdd0d3 transparent transparent;
      }
    }
  }
  .form-control {
    border: none;
    background-color: transparent;
  }
  &.datepicker-unit {
    position: relative;
    &:after {
      content: '\e906';
      position: absolute;
      bottom: 5px;
      right: 0;
      display: block;
      color: $blue-base;
      font-size: 1.25rem;
      font-family: 'ftm-icons' !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  &.simulate-input {
    border-color: $blue-base;
    .ng-select {
      width: 320px;
      .ng-select-container {
        color: $gray-darken-50;
        font-family: $font-family-semibold;
        .ng-value-container {
          .ng-placeholder {
            color: $gray-darken-50;
            font-family: $font-family-semibold;
          }
        }
      }
      .ng-arrow-wrapper .ng-arrow {
        border-color: $blue-base transparent transparent;
      }
    }
    .ng-clear-wrapper {
      color: $gray-lighten-30;
    }
  }
  .search-autocomplete & {
    padding-left: 2rem;
    border: none;
  }
}

.ng-select .ng-clear-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  .ng-clear {
    display: block;
  }
}

.form-error {
  .form-control {
    border-color: $red-base;
  }
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none;
}

.timepicker-wrapper {
  position: relative;
  .dropdown-menu {
    padding: $gap-xs;
    box-shadow: $elevation-2;
    border: 1px solid $border;
    border-radius: $border-radius;
    background-color: $white;
    width: 100%;
  }
  // arrows
  .btn {
    display: block;
    padding: 0.25rem;
    text-align: center;
    span {
      display: inline-block;
    }
  }
  // inputs
  .bs-timepicker-field {
    width: 100%;
  }
}

.option-line-height {
  line-height: 1;
}
.option-title {
  color: $gray-darken-20;
  font-size: 0.813rem;
  font-family: $font-family-semibold;
}
.option-meta {
  color: $gray-lighten-30;
  font-size: 0.813rem;
}

.cord-label {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.25rem 2.5rem 0.25rem 0;
  .label {
    margin: 0 0.5rem 0 0;
    background-color: $white;
    color: $gray-lighten-10;
    font-family: $font-family-semibold;
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 1px;
    text-transform: uppercase;
    .cloud-panel & {
      background-color: $gray-lighten-60;
    }
  }
  &:after {
    content: '';
    display: block;
    flex-grow: 1;
    height: 1px;
    background-color: $border-light;
  }
  .custom-switch {
    position: absolute;
    right: -9px;
    &::before {
      content: '';
      display: block;
      background-color: $white;
      width: 24px;
      height: 14px;
      position: absolute;
      left: -0.5rem;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: $green-base;
      background-color: $green-base;
    }
  }
}

.custom-control-label-sm {
  font-size: 0.8125rem;
  &::before,
  &::after {
    top: 0;
  }
}

.form-control {
  &::placeholder {
    font-family: $font-family-light;
  }
}

.recurrance-panel {
  padding: $gap-xs;
  border-radius: $border-radius;
  background-color: $gray-lighten-60;
  font-size: 0.8125rem;
  .day-options {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }
  .custom-control {
    margin-left: 1rem;
  }
  .custom-control-label {
    line-height: 2;
    &:hover {
      cursor: pointer;
    }
  }
}

.custom-switch {
  .custom-control-label {
    &::before {
      border-color: transparent;
      background-color: $gray-lighten-10;
    }
    &::after {
      background-color: $white;
    }
  }
  &.custom-switch-sm {
    min-height: 1rem;
    padding-left: 2.5rem;
    .custom-control-label {
      &::before {
        top: 0.188rem;
        height: 1rem;
        width: 1.938rem;
        left: -2.5rem;
      }
      &:after {
        top: 0.313rem;
        left: -2.375rem;
      }
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: $blue-base;
    border-color: $blue-base;
    background-color: $blue-base;
  }
}

.search-input {
  position: relative;
  margin-right: 0.5rem;
  border-bottom: 1px solid $border;
  .icon {
    position: absolute;
    left: 0.5rem;
    top: 0.25rem;
    font-size: 1.5rem;
  }
  .form-control {
    padding-left: 2.5rem;
    border: none;
    border-radius: 0;
    background-color: transparent;
  }
  .ng-select .ng-arrow-wrapper {
    display: none;
  }
  .app-input .ng-select {
    border: none;
  }
}

.search-options {
  padding-left: 10rem;
  padding-right: 2rem;
}

.select-input-type {
  position: absolute;
  left: 2.5rem;
  top: 0.438rem;
  width: 8rem;
  border-right: 1px solid $border;
  color: $gray-darken-40;
  font-family: $font-family-semibold;
  font-size: 0.75rem;
  cursor: pointer;
  .selected-input-type {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:after {
    content: '\e908';
    position: absolute;
    right: 0.5rem;
    top: 0.25rem;
    font-family: 'ftm-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }
  &.open {
    &:after {
      content: '\e90b';
    }
  }
}

.select-flex-dropdown {
  position: relative;
  cursor: pointer;
  .select-flex-toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 34px;
    padding-right: 2rem;
    &:after {
      content: '\e908';
      position: absolute;
      right: 0.5rem;
      top: 0.25rem;
      font-family: 'ftm-icons' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    }
  }
  .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
  &.open {
    .select-flex-toggle {
      &:after {
        content: '\e90b';
      }
    }
  }
  &.centered {
    .select-flex-toggle {
      align-items: center;
      padding-right: 0;
    }
    .dropdown-menu {
      inset: 100% 0px auto auto !important;
    }
  }
}

.custom-control {
  &.custom-checkbox {
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: $blue-base;
      background-color: $blue-base;
      color: $white;
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");
    }
  }
}

@include media-breakpoint-down(sm) {
  .app-input-inline {
    flex-direction: column;
    > label {
      align-self: flex-start;
    }
    > *,
    .ng-select {
      width: 100%;
    }
    &.simulate-input {
      .ng-select {
        width: 180px;
      }
    }
  }
}
