// Roster Type badges

.roster-badge {
  display: inline-block;
  border-radius: 2px;
  color: $white;
  font-family: $font-family-semibold;
  font-size: 11px;
  line-height: 12px;
  &.plain-shared {
    padding: 3px 0;
    color: $red-lighten-10;
    font-family: $font-family-bold;
    font-size: 0.75rem;
  }
  &.plain-global {
    padding: 3px 0;
    color: $yellow-lighten-30;
    font-family: $font-family-bold;
    font-size: 0.75rem;
  }
  &.plain-private {
    padding: 3px 0;
    color: $blue-darken-10;
    font-family: $font-family-bold;
    font-size: 0.75rem;
  }

  &.solid-shared {
    padding: 3px 8px;
    background-color: $red-lighten-10;
  }
  &.solid-global {
    padding: 3px 8px;
    background-color: $yellow-lighten-30;
  }
  &.solid-private {
    padding: 3px 8px;
    background-color: $blue-darken-10;
  }
}

// Priority badges

.priority-badge {
  padding: 3px 8px;
  border-radius: 20px;
  background: $blue-lighten-30;
  color: $gray-darken-50;
  font-family: $font-family-medium;
  font-size: 11px;
  line-height: 1;
  &.priority-ama {
    background: $priority-admin;
  }
  &.priority-pr {
    background: $priority-priority-roster;
  }
  &.priority-tr {
    background: $priority-teacher-request;
  }
  &.priority-rr {
    background: $priority-regular-roster;
  }
  &.priority-or {
    background: $priority-optional-roster;
  }
  &.priority-aa {
    background: $priority-advertise;
  }
  &.priority-na {
    background: $priority-normal;
  }
}

// Status

.status-dot {
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  &.status-active {
    background-color: $green-lighten-10;
  }
  &.status-inactive {
    background-color: $gray-lighten-10;
  }
}
.status-dot-badge {
  position: relative;
  display: inline-block;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  font-family: $font-family-semibold;
  line-height: 1;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    height: 8px;
    width: 8px;
    margin-top: -4px;
    border-radius: 100%;
  }
  &.status-active {
    color: $green-base;
    &::before {
      background-color: $green-lighten-10;
    }
  }
  &.status-inactive {
    color: $gray-base;
    &::before {
      background-color: $gray-lighten-10;
    }
  }
  &.status-danger {
    color: $red-base;
    &::before {
      background-color: $red-lighten-10;
    }
  }
}
.status-badge {
  display: inline-block;
  padding: 0.188rem 0.625rem;
  border: 2px solid $gray-base;
  border-radius: 35px;
  color: $gray-base;
  font-size: 0.875rem;
  font-family: $font-family-semibold;
  line-height: 1rem;
  &.status-active,
  &.status-processed {
    border-color: $green-lighten-10;
    color: $green-base;
  }
  &.status-inactive,
  &.status-pending {
    border-color: $gray-lighten-10;
    color: $gray-base;
  }
  &.status-processing {
    border-color: $orange-lighten-10;
    color: $orange-base;
  }
  &.status-failed {
    border-color: $red-base;
    background-color: $red-base;
    color: $white;
  }
  &.status-queued {
    border-color: $yellow-lighten-20;
    color: $yellow-base;
  }
}

// Counter
.badge-counter-container {
  position: relative;
}
.badge-counter {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 6px;
  border-radius: 20px;
  background: $gray-lighten-40;
  color: $gray-darken-20;
  font-size: 8px;
  font-family: $font-family-semibold;
  line-height: 10px;
  &.unread {
    background: $orange-lighten-20;
    color: $white;
  }
}

// Icon
.badge-icon {
  font-family: $font-family-semibold;
  .icon {
    padding: 0.25rem;
    border-radius: 100%;
    background-color: $gray-lighten-60;
  }
  &.success {
    color: $green-lighten-20;
    .icon {
      background-color: $green-lighten-40;
      color: $green-base;
    }
  }
  &.danger {
    color: $red-lighten-10;
    .icon {
      background-color: $red-lighten-30;
      color: $red-darken-10;
    }
  }
  &.warning {
    color: $orange-lighten-20;
    .icon {
      background-color: $orange-lighten-40;
      color: $orange-lighten-10;
    }
  }
}
