.announcement {
  padding: $gap-xxs $gap-xs;
  background: $yellow-lighten-50;
  font-size: 0.875rem;
  .announcement-title {
    display: flex;
    align-items: center;
    color: $yellow-lighten-20;
    font-size: 1rem;
    line-height: 1;
    .icon {
      font-size: 1.5rem;
      font-weight: normal;
    }
  }
  .announcement-body {
    padding: 0 1.25rem $gap-xs 1.25rem;
    color: $gray-darken-20;
  }
  &.announcement-system {
    background: linear-gradient(90deg, rgba($blue-lighten-50, 1) 0%, rgba($blue-lighten-30, 1) 100%);
    .announcement-title {
      color: $blue-base;
    }
  }
  .text-yellow {
    color: $yellow-lighten-20;
  }
}
