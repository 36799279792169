.calendar-nav {
  height: $calendar-nav-height;
}

.col-pill-tabs {
  text-align: right;
}

@include media-breakpoint-down(lg) {
  .calendar-nav {
    left: 0;
  }
}

@include media-breakpoint-down(sm) {
  .calendar-nav {
    height: $calendar-nav-height-mobile;
  }
  .col-datepicker {
    text-align: center;
  }
  .col-pill-tabs {
    margin-bottom: $gap-xs;
    text-align: center;
  }
}

.calendar-buttons-container {
  display: flex;
  flex-direction: row;
}

.calendar-buttons-container app-schedule-button {
  flex: 3; /* Takes up more space */
}

.calendar-buttons-container app-cancelation-button {
  flex: 1; /* Takes up less space */
}

.securly-red {
  color: #F35531;
}
